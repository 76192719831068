import { useAccess } from 'providers/access';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'resources/api/auth';

const PrivateRoute = ({ component, groupId = null, access = null, userType = null, ...rest }) => {
  const Comp = component;
  const { hasAccess, hasAccessInGroup } = useAccess();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getToken() ? (
          (access === null && userType === null) || (groupId ? hasAccessInGroup(groupId, access, userType) : hasAccess(access, userType)) ? (
            <Comp {...rest} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: location } }} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
